@import "variables";

.login-title {
  visibility: hidden;
}

.login-title:after {
  visibility: visible;
  content: "Anmelden bei leaftec Shop";
}

.login-wrapper-image-title {
  line-height: 1.75rem;
}

// Default Vendure light theme. The Clarity component custom properties
// are left as their defaults.
:root [cds-theme] {
  // Colors
  --color-grey-100: #fafafa;
  --color-grey-200: #f2f3f5;
  --color-grey-250: #e5e6eb;
  --color-grey-300: #bfc3cc;
  --color-grey-400: #97979a;
  --color-grey-500: #525456;
  --color-grey-600: #3f4445;
  --color-grey-700: #303233;
  --color-grey-800: #202223;
  --color-grey-900: #0f1011;

  --color-white: #fff;
  --color-black: #000;
  --color-weight-100: hsl(0 0% 95%);
  --color-weight-125: hsl(0 0% 93%);
  --color-weight-150: hsl(0 0% 90%);
  --color-weight-200: hsl(0 0% 85%);
  --color-weight-300: hsl(0 0% 75%);
  --color-weight-400: hsl(0 0% 65%);
  --color-weight-500: hsl(0 0% 55%);
  --color-weight-600: hsl(0 0% 45%);
  --color-weight-700: hsl(0, 0%, 35%);
  --color-weight-800: hsl(0 0% 25%);
  --color-weight-900: hsl(0 0% 15%);
  --color-weight-950: hsl(0 0% 10%);
  --color-weight-975: hsl(0 0% 7%);
  --color-weight-1000: hsl(0 0% 5%);

  --color-primary-100: hsl(120, 70%, 90%);
  --color-primary-125: hsl(120, 70%, 87%);
  --color-primary-150: hsl(120, 70%, 84%);
  --color-primary-200: hsl(120, 70%, 80%);
  --color-primary-300: hsl(120, 70%, 70%);
  --color-primary-400: hsl(120, 70%, 60%);
  --color-primary-500: hsl(120, 70%, 50%);
  --color-primary-600: hsl(120, 70%, 40%);
  --color-primary-700: hsl(120, 70%, 30%);
  --color-primary-800: hsl(120, 70%, 20%);
  --color-primary-900: hsl(120, 70%, 15%);
  --color-primary-950: hsl(120, 70%, 10%);
  --color-primary-975: hsl(120, 70%, 7%);
  --color-primary-1000: hsl(120, 70%, 5%);

  --color-accent-100: hsl(35 100% 95%);
  --color-accent-125: hsl(35 100% 93%);
  --color-accent-150: hsl(35 100% 90%);
  --color-accent-200: hsl(35 100% 85%);
  --color-accent-300: hsl(35 100% 75%);
  --color-accent-400: hsl(35 100% 65%);
  --color-accent-500: hsl(35 100% 55%);
  --color-accent-600: hsl(35 100% 45%);
  --color-accent-700: hsl(35 100% 35%);
  --color-accent-800: hsl(35 100% 25%);
  --color-accent-900: hsl(35 100% 15%);
  --color-accent-950: hsl(35 100% 10%);
  --color-accent-975: hsl(35 100% 7%);
  --color-accent-1000: hsl(35 100% 5%);

  --color-success-100: hsl(158 76% 95%);
  --color-success-125: hsl(158 76% 93%);
  --color-success-150: hsl(158 76% 90%);
  --color-success-200: hsl(158 76% 85%);
  --color-success-300: hsl(158 76% 75%);
  --color-success-400: hsl(158 76% 65%);
  --color-success-500: hsl(158 76% 55%);
  --color-success-600: hsl(158 76% 45%);
  --color-success-700: hsl(158 76% 35%);
  --color-success-800: hsl(158 76% 25%);
  --color-success-900: hsl(158 76% 15%);
  --color-success-950: hsl(158 76% 10%);
  --color-success-975: hsl(158 76% 7%);
  --color-success-1000: hsl(158 76% 5%);

  --color-warning-100: hsl(53 100% 95%);
  --color-warning-125: hsl(53 100% 93%);
  --color-warning-150: hsl(53 100% 90%);
  --color-warning-200: hsl(53 100% 85%);
  --color-warning-300: hsl(53 100% 75%);
  --color-warning-400: hsl(53 100% 65%);
  --color-warning-500: hsl(53, 100%, 47%);
  --color-warning-600: hsl(53 100% 45%);
  --color-warning-700: hsl(53 100% 35%);
  --color-warning-800: hsl(53 100% 25%);
  --color-warning-900: hsl(53 100% 15%);
  --color-warning-950: hsl(53 100% 10%);
  --color-warning-975: hsl(53 100% 7%);
  --color-warning-1000: hsl(53 100% 5%);

  --color-error-100: hsl(4 100% 95%);
  --color-error-125: hsl(4 100% 93%);
  --color-error-150: hsl(4 100% 90%);
  --color-error-200: hsl(4 100% 85%);
  --color-error-300: hsl(4 100% 75%);
  --color-error-400: hsl(4 100% 65%);
  --color-error-500: hsl(4, 73%, 51%);
  --color-error-600: hsl(4 100% 45%);
  --color-error-700: hsl(4 100% 35%);
  --color-error-800: hsl(4 100% 25%);
  --color-error-900: hsl(4 100% 15%);
  --color-error-950: hsl(4 100% 10%);
  --color-error-975: hsl(4 100% 7%);
  --color-error-1000: hsl(4 100% 5%);

  // Universal semantic colors
  --color-component-bg-100: var(--color-grey-100);
  --color-component-bg-200: var(--color-grey-200);
  --color-component-bg-250: var(--color-grey-250);
  --color-component-bg-300: var(--color-grey-300);
  --color-component-border-100: var(--color-grey-200);
  --color-component-border-200: var(--color-grey-300);
  --color-component-border-300: var(--color-grey-400);
  --color-text-100: var(--clr-global-font-color);
  --color-text-200: var(--clr-global-font-color-secondary);
  --color-text-300: var(--color-grey-400);
  --clr-global-font-color: hsl(198, 0%, 40%);
  --clr-list-item-color: var(--clr-global-font-color);
  --color-text-inverse: white;
  --color-text-active: var(--color-primary-800);

  // Component-specific colors
  --color-scrollbar-bg: var(--color-weight-150);
  --color-scrollbar-thumb: var(--color-weight-300);
  --color-scrollbar-thumb-hover: var(--color-weight-400);

  --color-top-bar-bg: white;
  --color-card-border: var(--color-weight-200);
  --color-card-bg: hsl(0 0% 99%);

  --color-left-nav-bg: var(--color-weight-100);
  --color-left-nav-text: var(--color-text);
  --color-left-nav-text-hover: var(--color-primary-700);
  --color-surface-bg: white;
  --color-page-header: hsl(0 0% 98%);
  --color-page-header-item-bg: var(--color-weight-125);

  --color-button-bg: hsl(0 0% 98%);
  --color-button-hover-bg: var(--color-weight-100);
  --color-button-ghost-bg: white;
  --color-button-ghost-text: var(--color-text-100);
  --color-button-ghost-hover-text: var(--color-primary-700);
  --color-button-ghost-hover-bg: var(--color-primary-100);
  --color-button-small-bg: var(--color-weight-150);
  --color-button-small-hover-bg: var(--color-weight-200);
  --color-button-small-text: var(--color-weight-700);

  --color-channel-switcher-bg: var(--color-weight-150);
  --color-channel-switcher-hover-bg: var(--color-weight-125);

  --color-icon-button: var(--color-grey-600);
  --color-icon-button-hover: var(--color-primary-600);
  --color-form-input-bg: white;
  --color-form-input-focus: var(--color-primary-100);

  --color-chip-warning-border: var(--color-warning-500);
  --color-chip-warning-text: var(--color-warning-800);
  --color-chip-warning-bg: var(--color-warning-150);
  --color-chip-success-border: var(--color-success-200);
  --color-chip-success-text: var(--color-success-800);
  --color-chip-success-bg: var(--color-success-150);
  --color-chip-error-border: var(--color-error-200);
  --color-chip-error-text: var(--color-error-800);
  --color-chip-error-bg: var(--color-error-150);

  --color-json-editor-background-color: var(--color-grey-200);
  --color-json-editor-text: var(--color-grey-600);
  --color-json-editor-string: var(--color-secondary-600);
  --color-json-editor-number: var(--color-primary-700);
  --color-json-editor-boolean: var(--color-primary-700);
  --color-json-editor-null: var(--color-grey-500);
  --color-json-editor-key: var(--color-success-800);
  --color-json-editor-error: var(--color-error-700);

  --color-table-header-border: var(--color-weight-200);
  --color-table-row-hover-bg: var(--color-weight-100);
  --color-table-row-active-bg: var(--color-primary-100);
  --color-table-row-separator: var(--color-weight-100);
  --data-table-filter-box-shadow: inset -1px 6px 5px 0px rgb(165 165 165 / 8%);

  --color-split-view-separator-border: var(--color-weight-150);
  --color-split-view-separator-resize-border: var(--color-primary-400);

  --color-dropdown-item-focus-outline: rgba(77, 207, 255, 0.53);

  // Layout
  --layout-content-max-width: 1400px;
  --left-nav-width: 0px;
  --surface-width: 100vw;
  --surface-margin-left: 8px;

  @media screen and (min-width: $breakpoint-small) {
    //--left-nav-width: 100px;
    //--surface-width: calc(100vw - 100px);
    //--surface-margin-left: 4px;
  }

  @media screen and (min-width: $breakpoint-medium) {
    --left-nav-width: 250px;
    --surface-width: calc(100vw - 250px);
    --surface-margin-left: calc(var(--space-unit) * 4);
  }

  @media screen and (min-width: $breakpoint-large) {
    --left-nav-width: 300px;
    --surface-width: calc(100vw - 300px);
    --surface-margin-left: calc(var(--space-unit) * 6);
  }

  // Login page
  --login-wrapper-inner-bg: var(--color-white);
  --color-login-gradient-top: var(--color-primary-800);
  --color-login-gradient-bottom: black;

  // Border radius
  --clr-global-borderradius: 4px;
  --border-radius-sm: calc(var(--clr-global-borderradius) / 2);
  --border-radius: var(--clr-global-borderradius);
  --border-radius-lg: calc(var(--space-unit) * 3);
  --border-radius-img: var(--clr-global-borderradius);
  --border-radius-input: var(--clr-global-borderradius);

  // typography
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --cds-global-typography-body-line-height: 24px;
  --cds-alias-typography-body-line-height: 16px;
  --cds-alias-typography-body-letter-spacing: normal;
  --cds-alias-typography-display-letter-spacing: normal;
  --cds-alias-typography-secondary-font-size: 13px;
  --cds-alias-typography-font-size-3: var(--font-size-sm);
  --cds-global-typography-font-size-4: var(--cds-alias-typography-secondary-font-size);

  // spacing
  --space-unit: 8px;

  // clarity styles
  --clr-link-visited-color: var(--color-weight-700);
  --clr-link-hover-color: var(--color-weight-700);
  --clr-link-active-color: var(--color-weight-700);
  --clr-link-color: var(--color-weight-700);
  --clr-list-item-color: var(--color-weight-700);
}
